<template>
  <div
    class="text-center"
    :class="color"
  >
    <b-spinner />
    <div class="mt-1">
      {{ loadingText }}
    </div>
  </div>
</template>

<script>
import { BSpinner } from 'bootstrap-vue'

export default {
  name: 'PreLoading',
  components: {
    BSpinner,
  },
  props: {
    color: {
      type: String,
      default: 'text-primary',
    },
    loadingText: {
      type: String,
      default: 'Lütfen Bekleyiniz...',
    },
  },
}
</script>
