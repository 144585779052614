<template>
  <b-card no-body>
    <b-card-header>
      <div class="d-block">
        <b-card-title class="mb-1">
          {{ chartTitle }}
        </b-card-title>
        <b-card-sub-title class="font-small-2 font-italic">
          {{ chartSubTitle }}
        </b-card-sub-title>
      </div>
      <div class="text-muted font-small-2">
        {{ chartPrefixText }}
      </div>
    </b-card-header>
    <b-card-body>
      <pre-loading
        v-if="loading"
        loading-text="Yükleniyor..."
        color="text-secondary bg-light-primary mx-2 mb-2 rounded p-2"
      />
      <div v-else>
        <vue-apex-charts
          v-if="donutChart.series.length > 0"
          type="donut"
          :height="heightSize"
          :options="donutChart.chartOptions"
          :series="donutChart.series"
        />
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BCardSubTitle, BCardHeader, BCardBody,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import PreLoading from '@/layouts/components/common/PreLoading.vue'

export default {
  components: {
    PreLoading,
    VueApexCharts,
    BCard,
    BCardTitle,
    BCardSubTitle,
    BCardHeader,
    BCardBody,
  },
  props: {
    chartTitle: {
      type: String,
      default: '',
    },
    chartSubTitle: {
      type: String,
      default: '',
    },
    chartPrefixText: {
      type: String,
      default: '',
    },
    heightSize: {
      type: Number,
      default: 450,
    },
    seriesData: {
      type: Array,
      required: true,
    },
    categoriesData: {
      type: Array,
      required: true,
    },
    colorsData: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      donutChart: {
        series: [],
        chartOptions: {
          legend: {
            show: true,
            position: 'bottom',
            fontSize: '12px',
            fontFamily: 'Montserrat',
            horizontalAlign: 'center',
            floating: false,
            itemMargin: {
              horizontal: 10,
              vertical: 5,
            },
          },
          colors: [],
          dataLabels: {
            enabled: true,
            formatter(val) {
              // eslint-disable-next-line radix
              return `${parseInt(val)}%`
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: '0.75rem',
                    fontFamily: 'Montserrat',
                  },
                  value: {
                    fontSize: '1.2rem',
                    fontWeight: 'bold',
                    fontFamily: 'Montserrat',
                    formatter(val) {
                      return `${new Intl.NumberFormat('tr-TR', { minimumFractionDigits: 2 }).format(val)}₺`
                    },
                  },
                  total: {
                    show: true,
                    fontSize: '1rem',
                    fontFamily: 'Montserrat',
                    label: 'Toplam Tahsilat',
                    formatter(val) {
                      const totals = val.globals.series.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
                      return `${new Intl.NumberFormat('tr-TR', { minimumFractionDigits: 2 }).format(totals)}₺`
                    },
                  },
                },
              },
            },
          },
          labels: [],
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: '1.5rem',
                        },
                        value: {
                          fontSize: '1rem',
                        },
                        total: {
                          fontSize: '1.5rem',
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
        },
      },
    }
  },
  watch: {
    seriesData: {
      deep: true,
      handler(val) {
        this.donutChart.series = val
      },
    },
    categoriesData: {
      deep: true,
      handler(val) {
        this.donutChart.chartOptions.labels = val
        this.donutChart.chartOptions = {
          ...this.donutChart.chartOptions,
        }
      },
    },
    colorsData: {
      deep: true,
      handler(val) {
        this.donutChart.chartOptions.colors = val
        this.donutChart.chartOptions = {
          ...this.donutChart.chartOptions,
        }
      },
    },
  },
  mounted() {
    this.setData()
  },
  methods: {
    setData() {
      this.donutChart.series = this.seriesData
      this.donutChart.chartOptions.labels = this.categoriesData
      this.donutChart.chartOptions.colors = this.colorsData
      this.donutChart.chartOptions = {
        ...this.donutChart.chartOptions,
      }
    },
  },
}
</script>
