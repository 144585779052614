<template>
  <b-card>
    <b-row>
      <b-col>
        <b-form-group
          label="Başlangıç Tarihi"
          label-for="sdate"
        >
          <b-form-datepicker
            id="sdate"
            v-model="reportParams.sdate"
            v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
            locale="tr"
            start-weekday="1"
            @input="getReport"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Bitiş Tarihi"
          label-for="edate"
        >
          <b-form-datepicker
            id="edate"
            v-model="reportParams.edate"
            v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
            locale="tr"
            start-weekday="1"
            @input="getReport"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import {
  BCard, BFormGroup, BFormDatepicker, BRow, BCol,
} from 'bootstrap-vue'

export default {
  name: 'ReportParams',
  components: {
    BCard,
    BFormGroup,
    BFormDatepicker,
    BRow,
    BCol,
  },
  props: {
    getReport: {
      type: Function,
      required: true,
    },
  },
  computed: {
    reportParams() {
      return this.$store.getters['reportBanks/reportParams']
    },
  },
}
</script>
