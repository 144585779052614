<template>
  <div>
    <report-params :get-report="getData" />
    <b-row>
      <b-col
        cols="12"
        md="8"
      >
        <banks-payments />
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <apex-donut-chart
          v-if="reportData.charts.bankPayments.labels.length"
          :loading="loading"
          chart-title="Bankalar"
          chart-sub-title="Tarih aralığındaki yapılan tahsilatların bankalara göre oranı"
          :chart-prefix-text="moment(reportParams.sdate).format('DD.MM.YYYY') + ' - ' + moment(reportParams.edate).format('DD.MM.YYYY')"
          :categories-data="reportData.charts.bankPayments.labels"
          :series-data="reportData.charts.bankPayments.series"
          :colors-data="reportData.charts.bankPayments.colors"
          :height-size="650"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
import ReportParams from '@/views/Reports/Banks/components/ReportParams.vue'
import BanksPayments from '@/views/Reports/Banks/components/BanksPayments.vue'
import ApexDonutChart from '@/views/Reports/components/ApexDonutChart.vue'

export default {
  name: 'BanksReportIndex',
  components: {
    BRow,
    BCol,
    ReportParams,
    BanksPayments,
    ApexDonutChart,
  },
  computed: {
    reportParams() {
      return this.$store.getters['reportBanks/reportParams']
    },
    loading() {
      return this.$store.getters['reportBanks/loading']
    },
    reportData() {
      return this.$store.getters['reportBanks/reportData']
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('reportBanks/getData', this.reportParams)
    },
  },
}
</script>
